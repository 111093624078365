// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "x_sM d_bC";
export var storyRowWrapper = "x_hw d_hw d_bJ";
export var storyLeftWrapper = "x_sN d_by d_bN";
export var storyWrapperFull = "x_sP d_cC";
export var storyWrapperFullLeft = "x_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "x_n9 d_hx";
export var storyLeftWrapperCenter = "x_sQ d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "x_sR d_hD";
export var storyHeader = "x_sS d_hC d_v d_cr";
export var storyHeaderCenter = "x_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "x_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "x_sT d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "x_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "x_sV d_ff d_Y";
export var imageWrapperFull = "x_sW d_v d_G d_bd d_Y";